import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'customers-list',
    loadChildren: () => import('./customers-list/customers-list.module').then( m => m.CustomersListPageModule)
  },
  {
    path: 'customer-details/:email',
    loadChildren: () => import('./customer-details/customer-details.module').then( m => m.CustomerDetailsPageModule)
  },
  {
    path: 'parking-station-list',
    loadChildren: () => import('./parking-station-list/parking-station-list.module').then( m => m.ParkingStationListPageModule)
  },
  {
    path: 'working-hours-view',
    loadChildren: () => import('./working-hours-view/working-hours-view.module').then( m => m.WorkingHoursViewPageModule)
  },
  {
    path: 'check-otp',
    loadChildren: () => import('./check-otp/check-otp.module').then( m => m.CheckOtpPageModule)
  },
  {
    path: 'parking-bin/:id',
    loadChildren: () => import('./parking-bin/parking-bin.module').then( m => m.ParkingBinPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./reset-password/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password-otp',
    loadChildren: () => import('./reset-password/reset-password-otp/reset-password-otp.module').then( m => m.ResetPasswordOtpPageModule)
  },
  {
    path: 'reset-password-verify',
    loadChildren: () => import('./reset-password/reset-password-verify/reset-password-verify.module').then( m => m.ResetPasswordVerifyPageModule)
  },
  {
    path: 'send-message-customer',
    loadChildren: () => import('./send-message-customer/send-message-customer.module').then( m => m.SendMessageCustomerPageModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'scanner',
    loadChildren: () => import('./scanner/scanner.module').then( m => m.ScannerPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
