import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import { Platform } from '@ionic/angular';

import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { Storage } from '@ionic/storage';

import {environment} from '../environments/environment';

import {EventService} from './services/event.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedIndex = 0;
  public device: string;
  public isLogin = false;
  public isAdmin = false;
  public appPages = [
    {
      title: 'Log in',
      url: '/login',
      icon: 'log-in',
      available: true
    },
    {
      title: 'Sign up',
      url: '/register',
      icon: 'create',
      available: true
    },
    {
      title: 'Home',
      url: '/home',
      icon: 'home',
      available: false
    },
    {
      title: 'Customers',
      url: '/customers-list',
      icon: 'person',
      available: false
    },
    {
      title: 'Parking stations',
      url: '/parking-station-list',
      icon: 'bicycle',
      available: false
    },
    {
      title: 'Scan QR Code',
      url: '/scanner',
      icon: 'barcode',
      available: false
    },
    {
      title: 'Contact us',
      url: '/contact-us',
      icon: 'call',
      available: false
    },
    {
      title: 'Histories',
      url: '/history',
      icon: 'albums',
      available: false
    },
    {
      title: 'profile',
      url: '/profile',
      icon: 'person',
      available: false
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'log-out',
      available: false
    }
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private events: EventService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    console.log('initializeApp');
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    // console.log(this.platform.platforms);
    // if (this.platform.is('android')) {
    //   this.device = 'platfoorm is android';
    // } else if (this.platform.is('ios')) {
    //   this.device = 'ios';
    // } else if (this.platform.is('desktop')) {
    //   this.device = 'platfoorm is desktop';
    //   this.appPages[5].available = true; // should be false
    // } else if (this.platform.is('pwa')) {
    //   this.device = 'platfoorm is pwa';
    //   this.appPages[5].available = false;
    // }
    this.isLogin = false;
    if (localStorage.getItem(environment.LOGIN) != null) {
      // console.log(JSON.parse(localStorage.getItem(environment.LOGIN)));
      const loginDTO = JSON.parse(localStorage.getItem(environment.LOGIN));
      if (loginDTO.accessToken) {
        this.isLogin = true;
      }
      const userRoles = loginDTO.roles;
      // console.log('3:' + userRoles);
      if (userRoles == null) {
          this.isLogin = false;
      }
      if (userRoles.includes('ROLE_ADMIN')) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
      this.loginMethod(userRoles);
      this.router.navigate(['/home']);
    }

    if (this.isLogin) {
      console.log('login');
    } else {
      console.log('NOT login');
      this.logoutMethod();
    }

    // console.log("storage readiness:" + this.storage.create());
    // console.log("storage keys:" + this.storage.keys());

    // this.storage.keys()
    //   .then((data) => {
    //     console.log('keys:' + JSON.stringify(data));
    //     if (JSON.stringify(data).includes('token')) {
    //       console.log('login');
    //       this.loginMethod('ROLE_USER');
    //       this.router.navigate(['/parking-station-list']);
    //     } else {
    //       console.log('NOT login');
    //       this.logoutMethod();
    //     }
    //   })
    //   .catch((error) => {
    //     this.isLogin = false;
    //     console.log('Promise rejected with ' + JSON.stringify(error));
    //   });

    this.events.getObservable().subscribe((data: string) => {
      // console.log('Data received: ', data);
      if (data === 'logout') {
        this.logoutMethod();
      } else {
        this.loginMethod(data);
      }
    });
  }

  loginMethod(data) {
    this.ngOnInit();
    this.appPages[0].available = false;
    this.appPages[1].available = false;
    this.appPages[2].available = true;
    // this.appPages[4].available = true;
    this.appPages[8].available = true;
    // console.log('loginMethod=' + data);
    if (data.includes('ROLE_ADMIN')) {
      this.appPages[3].available = true;
      this.appPages[4].available = true;
      this.appPages[7].available = true;
    } else {
      this.appPages[5].available = true;
    }
    this.appPages[6].available = true;
    this.appPages[9].available = true;
    console.log('login');
  }

  logoutMethod() {
    this.appPages[0].available = true;
    this.appPages[1].available = true;
    this.appPages[2].available = false;
    this.appPages[3].available = false;
    this.appPages[4].available = false;
    this.appPages[5].available = false;
    this.appPages[6].available = false;
    this.appPages[7].available = false;
    this.appPages[8].available = false;
    this.appPages[9].available = false;
    console.log('logout');
  }

  ngOnInit() {
    console.log('ngOnInit');
    const path = window.location.pathname.split('folder/')[1];
    // console.log('path=' + path);
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
  }

  ionViewWillEnter() {
    console.log('ionViewWillEnter');
    const path = window.location.pathname.split('folder/')[1];
    console.log('path=' + path);
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
      console.log('selectedIndex=' + this.selectedIndex);
    }
  }
}
